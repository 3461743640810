import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import service from '@/utils/request';
var handleFileError = function handleFileError(res, fileName) {
  if (typeof res.data !== "undefined") {
    if (res.data.type == "application/json") {
      var reader = new FileReader();
      reader.onload = function () {
        var message = JSON.parse(reader.result).msg;
        _Message({
          showClose: true,
          message: message,
          type: 'error'
        });
      };
      reader.readAsText(new Blob([res.data]));
    }
  } else {
    var downloadUrl = window.URL.createObjectURL(new Blob([res]));
    var a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = fileName;
    var event = new MouseEvent("click");
    a.dispatchEvent(event);
  }
};

// @Tags excel
// @Summary 导出Excel
// @Security ApiKeyAuth
// @accept application/json
// @Produce  application/octet-stream
// @Param data body model.ExcelInfo true "导出Excel文件信息"
// @Success 200
// @Router /excel/exportExcel [post]
export var exportExcel = function exportExcel(tableData, fileName) {
  service({
    url: "/excel/exportExcel",
    method: 'post',
    data: {
      fileName: fileName,
      infoList: tableData
    },
    responseType: 'blob'
  }).then(function (res) {
    handleFileError(res, fileName);
  });
};

// @Tags excel
// @Summary 导入Excel文件
// @Security ApiKeyAuth
// @accept multipart/form-data
// @Produce  application/json
// @Param file formData file true "导入Excel文件"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"导入成功"}"
// @Router /excel/importExcel [post]
export var loadExcelData = function loadExcelData() {
  return service({
    url: "/excel/loadExcel",
    method: 'get'
  });
};

// @Tags excel
// @Summary 下载模板
// @Security ApiKeyAuth
// @accept multipart/form-data
// @Produce  application/json
// @Param fileName query fileName true "模板名称"
// @Success 200
// @Router /excel/downloadTemplate [get]
export var downloadTemplate = function downloadTemplate(fileName) {
  return service({
    url: "/excel/downloadTemplate",
    method: 'get',
    params: {
      fileName: fileName
    },
    responseType: 'blob'
  }).then(function (res) {
    handleFileError(res, fileName);
  });
};